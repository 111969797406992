import { html, css } from 'Components';
import DrawerForm from 'Components/abstract/DrawerForm.js';
import { Lang } from 'Utils';

import config from './config.js';

class CounterMeasuresEdit extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      css`
        label {
          font-size:0.9em;
          padding-left:5px;
        }

        .padding {
          padding-left:5px;
        }

        box-styled[variant="warning"] {
          font-size:0.9em;
        }

        pre {
          font-size:0.6em;
        }
      `
    ]
  }

  static get properties() {
    return {
      filter: { type: String }
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'Countermeasure',
          },
        },
        french:{
          translation: {
            title:'Contre-mesure',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.eventUpdated = config.eventUpdated;
    this.itemTitleField = config.itemTitleField;
    this.getTranslatePayload = this.getTranslatePayload.bind(this);
    this._logEnable = false;
  }

  getTranslatePayload() {
    const payload = {
      title: Lang.lookup(this.item, 'title'),
      help: Lang.lookup(this.item, 'help'),
    }
    return payload;
  }

  onTranslated(ev) {
    this._lookupField('title', 'en').value = ev.detail.title || '';
    this._lookupField('help', 'en').value = ev.detail.help  || '';
    this.compareFields();
  }

  handleSubmitError(input, response) {
    if (!input) {
      console.warn('Field not found', response.field);
      return;
    }

    let msg = 'Valeur obligatoire';
    if (response.reason === 'MIN_LENGTH') {
      msg = `Longueur minimum de ${response.minValue} caractères`;
    }

    if (input.setCustomValidity) input.setCustomValidity(msg);
    if (input.reportValidity) input.reportValidity();
  }

  editorUpdated() {
    console.log('editorUpdated');
    this.compareFields();
    /*
    if (!ev.detail.reset) {
      this.item.content = ev.detail;
      this.compareFields();
    } 
    */
  }

  displaySelectRecipients(item) {
    if (!item.emailPrimaryAddress) return null;
    return `${item.name} (${item.emailPrimaryAddress})`;
  }

  render() {    
    if (!this.item) return '';

    return html`
      <modal-drawer>
        <form>
          <br/>
          <tab-group level="c" class="main" .item=${this.item}>
            <sl-tab slot="nav" panel="general">${this._tl('title')}</sl-tab>

            <sl-tab-panel name="general">
              <tab-group-lg level="f" .item=${this.item} .getTranslatePayload=${this.getTranslatePayload} @translated=${this.onTranslated}>
                <br/>
                <sl-input size="small" name="title" class="labol" label="Titre"></sl-input><br/>
                <sl-textarea size="small" name="help" class="labol" label="Aide"></sl-textarea><br/>
              </tab-group-lg>
              <sl-switch class="labol" name="customerResponseRequired" size="small" defaultValue="true" ?checked="${this.item.customerResponseRequired}">Réponse du client</sl-switch>  
              <sl-input size="small" name="cmdid" class="labol" label="ID" value="${this.item.cmid}" help-text="champ unique interne, valeur à demander au développeur"></sl-input><br/>
            </sl-tab-panel>


          </tab-group>
        </form>

        <sl-button slot="bt1" variant="primary" @click="${this.handleSubmit}">${this._tl('save')}</sl-button>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('cancel')}</sl-button>
        
      </modal-drawer>

      <modal-dialog id="modal-test-result" label="Détail SMTP">
        <div class="padding">
          <p>Le mail a été envoyé avec succès :</p>
          <pre></pre>
        </div>
        <sl-button slot="bt2" variant="primary" close="true">OK</sl-button>
      </modal-dialog>
    `;
  }

}

customElements.define('settings-countermeasure-edit', CounterMeasuresEdit);