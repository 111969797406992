import { html, unsafeHTML, css, formCss } from 'Components';
import DrawerForm from 'Components/abstract/DrawerForm.js';
import { Lang } from 'Utils';

import nodeTypes from './types.js';
import config from './config.js'

class NodeEdit extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        .marginbottom {
          margin-bottom:10px;
        }

        .bold {
          font-weight: bold;
        }

        sl-tab {
          position:relative;
        }

        sl-tab m-icon {
          position: absolute;
          right: -4px;
          top: 5px;
        }
      `
    ]
  }

  static get properties() {
    return {
      item: { type: Object }
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
          },
        },
        french:{
          translation: {
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.eventUpdated = config.eventUpdated;
    this.itemTitleField = config.itemTitleField;
  }

  getNodeTypeName(nodeType) {
    return nodeTypes[nodeType].name;
  }
  
  getParentName(item) {
    if (!item.parentId) return '';
    const parent = this.parent.items.find(i => i._id === item.parentId);
    if (!parent) return;
    return Lang.lookup(parent, this.itemTitleField);
  }

  getItemTitle(opts = {}) {
    const nodeTypeName = this.getNodeTypeName(this.item.nodeType);
    if (opts.mode === 'add') {
      if (!this.item.parentId) {
        return `${nodeTypeName}`
      } else {
        return `${nodeTypeName} dans ${this.getParentName(this.item)}`
      }
    } else {
      if (nodeTypes[this.item.nodeType].treeDisplayFields) {
        const title = nodeTypes[this.item.nodeType].treeDisplayFields.map(f => this.item[f]).join(' ');
        return `${nodeTypeName} ${title}`
      } else {
        return `${nodeTypeName} ${this.item.name ? `/ ${this.item.name}` : ''}`
      }
    }
  }

  handleSubmitError(input, response) {
    if (!input) {
      console.warn('Field not found', response.field);
      return;
    }

    let msg = 'Valeur obligatoire';
    if (response.reason === 'MIN_LENGTH') {
      msg = `Longueur minimum de ${response.minValue} caractères`;
    }

    if (input.setCustomValidity) input.setCustomValidity(msg);
    if (input.reportValidity) input.reportValidity();
  }

  renderField(field, tab) {
    
    let component = '';

    switch (field.component) {

      case 'sl-input':
        component = `<sl-input 
            class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
            ${field.style ? `style="${field.style}"` : ''}
            size="small" 
            name="${field.name}"
            spellcheck="false"
            defaultValue="${field.sameDefaultValue ? this.item[field.name] : field.defaultValue || ''}"
            value="${this.item[field.name] || field.defaultValue || ''}"
            placeholder="${field.placeholder || ''}"
            help-text="${field.help || ''}"
            ${field.type ? `type="${field.type}"` : ''}
            label="${field.label}">
          </sl-input>`;
          break;

      case 'sl-textarea':
        component = `<sl-textarea 
            class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
            ${field.style ? `style="${field.style}"` : ''}
            size="small" 
            spellcheck="false"
            name="${field.name}" 
            defaultValue="${field.sameDefaultValue ? this.item[field.name] : ''}"
            value="${this.item[field.name] || field.defaultValue || ''}"
            help-text="${field.help || ''}"
            label="${field.label}">${this.item[field.name]}</sl-textarea>`;
            break;

      case 'sl-switch':
        component = `<sl-switch 
            class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
            ${field.style ? `style="${field.style}"` : ''}
            size="small" 
            name="${field.name}" 
            defaultValue="${field.sameDefaultValue ? this.item[field.name] : ''}"
            ?checked="${this.item[field.name]}" 
            >${field.label}</sl-switch><br/>`;
            break;

      case 'sl-color-picker':
        component = `<div class="labol">
            <label>${field.label}</label>
            <sl-color-picker 
              class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
              ${field.style ? `style="${field.style}"` : ''}
              size="small" 
              name="${field.name}" 
              defaultValue="${field.sameDefaultValue ? this.item[field.name] : ''}"
              value="${this.item[field.name] || field.defaultValue || ''}"
              help-text="${field.help || ''}"
              swatches="#d0021b; #f5a623; #f8e71c; #8b572a; #7ed321; #417505; #bd10e0; #9013fe;#4a90e2; #50e3c2; #b8e986; #000; #444; #888; #ccc; #fff;"
              ></sl-color-picker>
            </div>`;
            break;

      case 'section-header':
        component = `<section-header 
            class="${field.bold ? 'bold' : ''}"
            ${field.variant ? `variant="${field.variant}"` : ''}
            ${field.style ? `style="${field.style}"` : ''}
            size="${field.size || 'normal'}" 
          >${field.label}</section-header><br/>`;
          break;

      case 'box-styled':
        component = `<box-styled 
            class="${field.bold ? 'bold' : ''}"
            ${field.variant ? `variant="${field.variant}"` : ''}
            ${field.style ? `style="${field.style}"` : ''}
            size="${field.size || 'normal'}" 
          >${field.label}</box-styled><br/>`;
          break;

      case 'select-validation-email-template':
        component = `<select-validation-email-template
            class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
            ${field.style ? `style="${field.style}"` : ''}
            name="${field.name}"
            label="${field.label}"
            search
            size="small"
            sort="name"
            value="${this.item[field.name] || ''}"
          ></select-validation-email-template>`;
          break;
      
      case 'select-validation-sms-template':
        component = `<select-validation-sms-template
            class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
            ${field.style ? `style="${field.style}"` : ''}
            name="${field.name}"
            label="${field.label}"
            search
            size="small"
            sort="name"
            value="${this.item[field.name] || ''}"
          ></select-validation-sms-template>`;
          break;
      
      case 'select-validation-period-interval':
        component = `<select-validation-period-interval
            class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
            ${field.style ? `style="${field.style}"` : ''}
            name="${field.name}"
            label="${field.label}"
            size="small"
            value="${this.item[field.name] || ''}"
          ></select-validation-period-interval>`;
          break;

      case 'select-gender':
        component = `<select-gender
            class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
            ${field.style ? `style="${field.style}"` : ''}
            name="${field.name}"
            label="${field.label}"
            size="small"
            value="${this.item[field.name] || ''}"
          ></select-gender>`;
          break;

      case 'select-human-role':
        component = `<select-human-role
            class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
            ${field.style ? `style="${field.style}"` : ''}
            name="${field.name}"
            label="${field.label}"
            size="small"
            value="${this.item[field.name] || ''}"
          ></select-human-role>`;
          break;
      
      case 'select-permissions':
        component = `<select-permissions
            class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
            ${field.style ? `style="${field.style}"` : ''}
            name="${field.name}"
            label="${field.label}"
            size="small"
            value="${this.item[field.name] || ''}"
            merged="${this.item.permissions_merged ? this.item.permissions_merged[field.name] || '' : ''}"
          ></select-permissions>`;
          break;

      case 'box-pre':
        component = `<box-pre>${JSON.stringify(this.item, null, 4)}</box-pre>`;
        break;

      case 'box-json':
        component = `<box-json>${JSON.stringify(this.item, null, 4)}</box-json>`;
        break;

      case 'blank':
        component = '<br/>';
        break;
    }

    // if we don't use unsafeHTML, there is some problem passing parameters, like style or input type ..    
    return unsafeHTML(`${component}`);

    //return component;
    
  }

  handleTestValidation() {
    this.compareFields();
  }

  renderForm() {
        
    if (!this.item) return '';

    const tabs = nodeTypes[this.item.nodeType].tabs;

    if (!tabs || !tabs.length) {
      return html`
        <box-styled variant="warning">
          <p>Node type &laquo;<b> ${this.item.nodeType} </b>&raquo; not yet implemented</p>
        </box-styled>`;
    }

    return html`
      <form>
        <tab-group level="t" .item=${this.item}>
          ${tabs.map(tab => {
            if (tab.hidden && tab.hidden()) return;
            return html`
              <sl-tab slot="nav" panel="${tab.name}">${unsafeHTML(tab.title)}</sl-tab>
              <sl-tab-panel name="${tab.name}">
                ${tab.fields_hidden?.map(field => this.renderField(field, tab))}
                <scrollable-component scrollbar-visibility="always">
                  ${tab.fields_lgs?.length 
                    ? html`
                        <tab-group-lg level="lg" .item=${this.item} .getTranslatePayload=${this.getTranslatePayload} @translated=${this.onTranslated}>
                          <br/>
                          ${tab.fields_lgs?.map(field => this.renderField(field, tab))}
                        </tab-group-lg>
                      `
                    : ''
                  }
                  ${tab.fields?.map(field => this.renderField(field, tab))}
                </scrollable-component>
              </sl-tab-panel>
            `
          })}
        </tab-group>
      </form>
    `;
  }

  render() {    
    return html`
      <modal-drawer name="modal-node-edit">
        <style type="text/css">${this.constructor.styles}</style>
        ${this.renderForm()}
        <sl-button slot="bt1" variant="primary" @click="${this.handleSubmit}">${this._tl('save')}</sl-button>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('cancel')}</sl-button>
      </modal-drawer>

    `;
  }

}

customElements.define('classification-node-edit', NodeEdit);

