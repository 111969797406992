import { PageElement, html } from 'Components';
import TableBasic from 'Components/abstract/TableBasic.js';
import { Lang } from 'Utils';

import './edit.js';
import config from './config.js';

class CounterMeasuresList extends TableBasic {
  static get translations() {
    return [
      super.translations,
      [
        {
          english:{
            translation: {
            },
          },
          french:{
            translation: {
            }
          }
        }
      ]
    ];
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.itemTitleField = config.itemTitleField;
    this.eventUpdated = config.eventUpdated;
    this.itemModel = { };
  }

  _renderEditComponent() {
    return html`<settings-countermeasure-edit id="modal-edit"></settings-countermeasure-edit>`;
  }

  _getTableHeader() {
    const totalCount = this.items?.length || 0;
    const totalText = totalCount >= 1 ? 'templates' : 'template';
    return `${totalCount} ${totalText}`;
  }
  
  _renderItems() {
    if (!this.items?.length) return;

    return html`
      <table>
        <thead>
          <tr>
            <th>Titre</th>
            <th>Aide</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        ${this.items.map((item) => html`
          <tr @click="${(ev) => this._editItem(ev, item)}" data-id="${item._id}">
            <td>${Lang.lookup(item, 'title')}</td>
            <td>${Lang.lookup(item, 'help')}</td>
            <td class="icon">
              <sl-dropdown>
                <m-icon slot="trigger" name="more_vert"></m-icon>
                <sl-menu>
                  <sl-menu-item @click="${(ev) => this._editItem(ev, item)}"><m-icon slot="prefix" name="edit"></m-icon>${this._tl('modify')}</sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item @click="${(ev) => this._deleteItemConfirm(ev, item)}"><m-icon slot="prefix" name="delete"></m-icon>${this._tl('remove')}</sl-menu-item>
                </sl-menu>
              </sl-dropdown>
            </td>
          </tr>
        `)}
        </tbody>
      </table>
      <br/><br/><br/>
    `;
  }

}

customElements.define('page-settings-countermeasures-list', CounterMeasuresList);


class CounterMeasuresPage extends PageElement {
  render() {
    return html`
        <section-header micon="database" backroute="../">Contre-mesures</section-header><br/>
        <box-styled variant="info">Les contre-mesures déjà affectées à des incidents ne sont pas impactés par les modifications ou suppression des contre-mesures ci-dessous</box-styled>
        <page-settings-countermeasures-list></page-settings-countermeasures-list>
    `;
  }
}

customElements.define('page-soc-countermeasures', CounterMeasuresPage);
