import { PageElement, html, css } from 'Components';

import './countermeasures';
import './classifications';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display:grid;
          grid-template-columns: repeat(5, 1fr); /* Crée 3 colonnes de taille égale */
          margin:10px;
          gap:20px;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
            margin:10px;
          }
        }
      `
    ];
  }

  static get translations() {
    return [
      {
        english:{
          translation: {
            title:'Settings',
            contermeasures:'Countermeasures',
            classifications:'Classifications',
            subclassifications:'Sub-classifications',
          },
        },
        french:{
          translation: {
            title:'Paramètres',
            contermeasures:'Contre-mesures',
            classifications:'Classifications',
            subclassifications:'Sous-classifications',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.layout = '';
  }
  
  render() {
    return html`
      <section-header size="big" backroute="../" micon="tune">${this._tl('title')}</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="database" aroute="./countermeasures">${this._tl('contermeasures')}</box-styled>
        <box-styled layout="${this.layout}" micon="database" aroute="./classifications">${this._tl('classifications')}</box-styled>
      </div>
      <br/>
    `;
  }

}

customElements.define('page-soc-settings-home', Page);